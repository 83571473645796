import getCookie from "@utils/getCookie";

export default function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isApple() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export const isRewardsIOSApp = window?.webkit?.messageHandlers?.rewardsApp;

export const isAlloyApp = () => window?.webviewAlloyApp || getCookie("is_ab_app") === "new";
