export const DASHBOARD = "dashboard";
export const CR = "rewards";

// For Resident-facing features only
export const CR_TOS_URL = "https://id.realpage.com/terms";
export const CR_PRIVACY_URL = "https://communityrewards.me/resident-privacy-policy";
export const REPORT_SECURITY_CONCERN_URL = "https://www.realpage.com/support/security/responsible-disclosure/";
export const LoyaltyTcURL = "https://www.realpage.com/legal/rewards-terms-and-conditions/";
export const LoyaltyPpURL = "https://www.realpage.com/legal/rewards-privacy-policy/";

// For Dashboard use only
export const MM_TOS_URL = "https://communityrewards.me/dashboard-terms-of-service";
export const MM_PRIVACY_URL = "https://communityrewards.me/dashboard-privacy-policy";
export const MM_SERV_AG_URL = "https://communityrewards.me/saasagreementstandard";

/** CR CPA ACTION ID */
export const CPA_ACTION_ID = 2015822;

export const OPEN_ASIDE = "open_aside";
