import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ConstrictedModal from "../molecules/ConstrictedModal";
import AuthHeader from "../molecules/AuthHeader";
import LoginSvg from "../../assets/images/login.svg";
import useWindowSize from "../../hooks/useWindowSize";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import Spacing from "../layouts/Spacing";

const UpdateAccountWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

export default function UpdateAccount({ email, programAssets, handleLoginRedirect }) {
  const location = useLocation();
  const { isMobile } = useWindowSize();

  const cidpEmail = email || location?.state?.email;

  const onComplete = (event) => {
    event.preventDefault();
    handleLoginRedirect(cidpEmail, true);
  };

  return (
    <UpdateAccountWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </Link>
      )}

      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <LoginSvg width="100%" height="100%" />
          </ConstrictedModal.ModalLeft>

          <ConstrictedModal.ModalRight>
            <AuthHeader mainText="Your Account Needs to be Updated" secondaryText="" />

            <Spacing padding="24px">
              <Spacing margin="24px 0">
                <Typography variant="subtitle1" align="center">
                  Let&apos;s update you to a single login account
                </Typography>
              </Spacing>

              <MaterialButton
                disabled={!cidpEmail}
                onClick={onComplete}
                variant="contained"
                color="secondary"
                type="button"
                fullWidth
              >
                Update
              </MaterialButton>
              <Spacing height="8" />

              <Spacing margin="24px 0">
                <Typography variant="body2">
                  Once updated, you can use your single login account to access our entire suite of products.
                </Typography>
              </Spacing>
            </Spacing>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>

      {!isMobile && (
        <FooterWrapper>
          <Spacing marginTop="8px" />
        </FooterWrapper>
      )}
    </UpdateAccountWrapper>
  );
}

UpdateAccount.propTypes = {
  email: PropTypes.string,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
    propertyId: PropTypes.string,
  }).isRequired,
  handleLoginRedirect: PropTypes.func.isRequired,
};

UpdateAccount.defaultProps = {
  email: undefined,
};
