/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import withTheme from "@mui/styles/withTheme";
import { styled, css } from "@mui/material/styles";
import classNames from "classnames";
import { rgba } from "polished";
import Button from "@mui/material/Button";
import { ButtonProps } from "@mui/material/Button/Button";
import { InputProps } from "@mui/material/Input/Input";
import getColorFromTheme from "@style/getColorFromTheme";
import { BUTTON_SIZES } from "@constants/styleConstants";

type HexColor = `#${string}`;

export const colorOptions = [
  "inherit",
  "default",
  "primary",
  "secondary",
  "textPrimary",
  "textSecondary",
  "alert",
  "success",
  "warning",
  "backgroundColor",
  "mediumGrey",
  "lightGrey",
] as const;

type ColorOptions = HexColor | (typeof colorOptions)[number];

export const textColorOptions = [...colorOptions, "contrastPrimary", "contrastSecondary", "white", "black"] as const;

export interface MaterialButtonProps extends Omit<ButtonProps, "color"> {
  className?: string;
  inputProps?: InputProps;
  color?: ColorOptions;
  textColor?: (typeof textColorOptions)[number];
  square?: boolean;
  makeItPop?: boolean;
  iconPadding?: boolean;
}

/** checks to see if the color is default of inherit - set it to */
function defaultColors(color: string) {
  return ["default", "inherit"].includes(color) ? "secondary" : color;
}

function validVariant(variant: MaterialButtonProps["variant"] | "light") {
  if (variant === "light" || variant === "text") {
    return "outlined";
  }
  return variant;
}

function sizeAndFontCss(size: string, iconPadding: boolean) {
  switch (size) {
    case BUTTON_SIZES.small:
      return `
        font-size: .875rem;
        padding: ${iconPadding ? "4px" : "7px 13px"};
        min-width: ${iconPadding ? "10px" : "120px"};
      `;
    case BUTTON_SIZES.large:
      return `
        font-size: 1.125rem;
        padding: ${iconPadding ? "12px" : "13px 18px"};
        min-width: ${iconPadding ? "10px" : "120px"};
      `;
    default: // Medium
      return `
        font-size: 1rem;
        height: 48px;
        padding: ${iconPadding ? "8px" : "10px 16px"};
        min-width: ${iconPadding ? "10px" : "120px"};
      `;
  }
}

function basicCss(color: string, size: string, iconPadding: boolean) {
  return `
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    color: ${color};
    text-transform: capitalize;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0.02em;
    ${sizeAndFontCss(size, iconPadding)}
  `;
}

const StyledButton = styled(
  ({
    variant,
    /* eslint-disable unused-imports/no-unused-vars */
    color,
    textColor,
    iconPadding,
    /* eslint-enable unused-imports/no-unused-vars */
    ...rest
  }: MaterialButtonProps) => <Button variant={validVariant(variant)} {...rest} />
)`
  &&& {
    .MuiButton-startIcon {
      margin-left: 0px;
    }
    .MuiButton-endIcon {
      margin-right: 0px;
    }
    min-width: 120px;
    max-width: 288px;
    border-radius: 12px;
    color: #ffffff;
    ${({ variant, theme, color, disabled, textColor, size, iconPadding }) => {
      const colorVariant = getColorFromTheme(theme, defaultColors(color)) || "white";
      const textColorVariant = getColorFromTheme(theme, defaultColors(textColor)) || "white";
      switch (variant) {
        case "contained":
          return css`
            background-color: ${colorVariant};
            box-shadow: unset;
            ${basicCss(textColorVariant, size, iconPadding)}
            ${disabled &&
            `
              background-color: ${theme.palette.backgroundColor.main} !important;
              color: ${theme.palette.darkGrey.main} !important;
            `}
          `;
        case "outlined":
          return css`
            background-color: inherit;
            ${basicCss(colorVariant, size, iconPadding)}
            border: 1px solid ${theme.palette.lightGrey.main};
            &:hover {
              border: 1px solid ${theme.palette.lightGrey.main};
            }
            ${disabled &&
            `
              border: 1px solid ${theme.palette.lightGrey.main};
              color: ${theme.palette.darkGrey.main} !important;
              box-shadow: unset;
            `}
          `;
        case "light":
          return css`
            border: none;
            background-color: ${rgba(colorVariant, 0.1)};
            ${basicCss(colorVariant, size, iconPadding)}
            ${disabled &&
            `
              background-color: ${rgba(theme.palette.backgroundColor.main, 0.4)};
              color: ${theme.palette.lightGrey.main} !important;
              box-shadow: unset;
            `}
          `;
        case "text":
          return css`
            background-color: inherit;
            ${basicCss(colorVariant, size, iconPadding)}
            border: 1px solid ${theme.palette.lightGrey.main};
            &:hover {
              border: 1px solid ${theme.palette.lightGrey.main};
            }
            ${disabled &&
            `
              color: ${theme.palette.darkGrey.main} !important;
              box-shadow: unset;
            `}
          `;
        default:
          return "";
      }
    }}
  }
`;

const MaterialButton: React.FC<MaterialButtonProps> = (props) => {
  const { className, inputProps, ...otherProps } = props;
  let spreadIP;
  if (inputProps || otherProps["data-testid"]) {
    spreadIP = {
      ...(otherProps["data-testid"] && { "data-testid": otherProps["data-testid"] }),
      ...inputProps,
    };
  }
  return <StyledButton className={classNames("react-css", className)} {...spreadIP} {...otherProps} />;
};

MaterialButton.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
  className: "",
  disabled: false,
  textColor: undefined,
  inputProps: undefined,
  square: false,
  makeItPop: false,
  iconPadding: false,
  onClick: () => {},
  type: undefined,
  fullWidth: false,
};

export default withTheme(MaterialButton);
