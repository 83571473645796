import { defaultAmenifyData, defaultTrackingAttributes, productData } from "./utils";
import { CLICK, VIEW, EARN } from "./constants";

export function amenifySurveryCardShownTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: VIEW,
    context: "AmenifySurveryCardShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveryCardClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveryCardClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveryAsideShownTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: VIEW,
    context: "AmenifySurveryAsideShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveryOptInClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveryOptInClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveryExploreClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveryExploreClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveryBenefitsClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveryBenefitsClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}
