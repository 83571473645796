// eslint-disable-next-line import/order
import logRocketStatus from "@utils/logrocketSetup";
import React from "react";
import PropTypes from "prop-types";
import track from "react-tracking";
import setupLogRocketReact from "logrocket-react";
import LogRocket from "logrocket";
import TRUST_DASHBOARD_EMAILS from "@constants/emailConstants";
import ThemeWrapper from "../../../style/ThemeWrapper";
import ErrorBoundary from "../../utility/error/ErrorBoundary";
import { buildRoutePrefix } from "../../../utils/urls";
import CRLogoBlue from "../../../assets/images/crLogoBlue.png";
import CRLogo from "../../../assets/images/cr-logo-white.png";
import PropertySelectRouter from "./PropertySelectRouter";
import { sentryInit } from "../../../utils/sentry";
import AuthWrapperBackground from "./AuthWrapperBackground";
import Router from "../../../Router";
import { AuthenticationContextProvider } from "../../../contexts/AuthenticationContext";
import { processAuthenticationTrackingData, authenticationAppTrackingAttributes } from "../../../analytics";

@track((props) => authenticationAppTrackingAttributes(props), {
  dispatch: processAuthenticationTrackingData,
  dispatchOnMount: true,
})
class PropertySelectApp extends React.Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "development" && !window.Cypress) {
      sentryInit(props.railsContext);

      if (logRocketStatus) {
        if (TRUST_DASHBOARD_EMAILS.includes(props.email)) return;

        setupLogRocketReact(LogRocket);
      }
    }
  }

  render() {
    const { management, whiteLabel, railsContext, managementSlug, ...otherProps } = this.props;
    const programName = management?.whiteLabelName || "Community Rewards";
    const isWhiteLabel = whiteLabel.enabled;
    const logoPrimary = isWhiteLabel ? whiteLabel.primaryLogoUrl : CRLogo;
    const logoSecondary = isWhiteLabel ? whiteLabel.secondaryLogoUrl : CRLogoBlue;
    const programAssets = {
      name: programName,
      isWhiteLabel,
      logoPrimary,
      logoSecondary,
    };

    const baseUrl = buildRoutePrefix(railsContext);
    const propertySelectUrl = `${baseUrl}${managementSlug && `/${managementSlug}`}`;

    return (
      <ThemeWrapper whiteLabel={whiteLabel}>
        <ErrorBoundary inline>
          <AuthWrapperBackground>
            <AuthenticationContextProvider {...this.props}>
              <Router basename={`${propertySelectUrl || "/"}`}>
                <PropertySelectRouter {...otherProps} baseUrl={baseUrl} programAssets={programAssets} />
              </Router>
            </AuthenticationContextProvider>
          </AuthWrapperBackground>
        </ErrorBoundary>
      </ThemeWrapper>
    );
  }
}

PropertySelectApp.propTypes = {
  email: PropTypes.string,
  managementSlug: PropTypes.string,
  hasUser: PropTypes.bool,
  hasIncentive: PropTypes.bool,
  railsContext: PropTypes.shape({
    railsEnv: PropTypes.string,
  }).isRequired,
  management: PropTypes.shape({
    productName: PropTypes.string,
    whiteLabelName: PropTypes.string,
  }),
  whiteLabel: PropTypes.shape({
    enabled: PropTypes.bool,
    primaryLogoUrl: PropTypes.string,
    secondaryLogoUrl: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
};

PropertySelectApp.defaultProps = {
  email: undefined,
  managementSlug: undefined,
  hasUser: false,
  hasIncentive: false,
  management: {},
  whiteLabel: {},
};

export default PropertySelectApp;
