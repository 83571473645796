import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { styled, useTheme } from "@mui/material/styles";
import AuthTokenExpired from "@components/features/AuthTokenExpired";
import EmailConfirm from "@components/features/EmailConfirm";
import Login from "@components/features/Login";
import NewCreateAccount from "@components/features/NewCreateAccount";
import UpdateAccount from "@components/features/UpdateAccount";
import OrganicLanding from "@components/organisms/OrganicLanding";
import PasswordReset from "@components/features/PasswordReset";
import PasswordResetExpired from "@components/features/PasswordResetExpired";
import PasswordResetForm from "@components/molecules/PasswordResetForm";
import PropertyDisabled from "@components/features/PropertyDisabled";
import SentMagicLink from "@components/features/SentMagicLink";
import ValueProposition from "@components/features/ValueProposition";
import AuthBackground from "@assets/images/authBackground.svg";
import { zIndexNewAuthBackground } from "@constants/zIndexConstants";
import { media } from "@utils/style";
import usePrevious from "@hooks/usePrevious";
import useWindowSize from "@hooks/useWindowSize";
import { sendMagicLink } from "@services/usersService";

const bgStarColor = (prim, sec) => (prim === sec ? "#002856" : prim);

const AuthenticationWrapper = styled("div")`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100%;
  .background {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .behind {
    position: fixed;
    z-index: ${zIndexNewAuthBackground};
  }
  .background #start {
    stop-color: ${(props) => props.secondary} !important;
  }
  .background #end {
    stop-color: ${(props) => props.primary} !important;
  }
  .background .bg_star {
    fill: ${(props) => bgStarColor(props.primary, props.secondary)} !important;
  }
  a {
    text-decoration: none;
  }
`;

const CenterWrapper = styled("div")`
  display: flex;
  justify-content: center;
  height: 100%;
  ${media.mobile`
    height: unset;
  `}
`;

const DefaultPageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  ${media.mobile`
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  `}
`;

const transitionOffset = 100;

const getDirection = (newPath, lastPath, isMobile) => {
  const toRoot = newPath === "/";
  const fromRoot = lastPath === "/";

  if (isMobile) {
    return toRoot ? "left" : "right";
  }
  if (newPath === "/value" && fromRoot) return "down";
  if (!toRoot && !fromRoot && newPath !== "/value" && lastPath !== "/value") return "down";
  if (lastPath === "/value" && toRoot) return "up";
  if (toRoot && lastPath !== "/value") return "left";
  return "right";
};

const createTransformDirection = (direction, isExit = false) => {
  const offset = isExit ? transitionOffset : -transitionOffset;
  switch (direction) {
    case "up":
      return { y: `${offset}%` };
    case "down":
      return { y: `${-offset}%` };
    case "left":
      return { x: `${-offset}%` };
    case "right":
      return { x: `${offset}%` };
    default:
      return { x: `${offset}%` };
  }
};

export default function Authentication(authProps) {
  const { routePrefix, programAssets, propertyDisabled, baseUrl, hasEmailAuth, useCidp, cidpUser, ...otherProps } =
    authProps;

  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const lastLocation = usePrevious(location) || { pathname: "/" };
  const { isMobile } = useWindowSize();

  const handleLoginRedirect = (email, enrollCIDP = false) => {
    sendMagicLink({ email, propertyId: programAssets.propertyId, enrollCIDP })
      .then((data) => {
        if (data.route) {
          history.push(data.route, data.parameters);
        } else if (data.location) {
          window.location.href = data.location;
        } else {
          history.push("/link-sent", { email });
        }
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
      });
  };

  useEffect(() => {
    if (!otherProps.hasUser && otherProps.playerId && location.pathname === "/") {
      history.push("/create-account");
    }
    if (otherProps.hasUser && otherProps.playerId && location.pathname === "/") {
      history.push("/login", { email: otherProps.email });
    }
  }, [otherProps.email, otherProps.playerId, otherProps.hasUser]);

  useEffect(() => {
    if (propertyDisabled) {
      history.push("/property-disabled");
    }
  }, []);

  const organicPath = window.location.origin.includes("community")
    ? window.location.origin
    : `${window.location.origin}/rewards`;

  const centerAnimation = { x: 0, y: 0, width: "100%", height: "100%" };
  const transition = { duration: 0.5 };

  const transitionVariants = {
    initial: (dir) => ({
      width: "100%",
      height: "100%",
      position: "absolute",
      overflow: "hidden",
      ...createTransformDirection(dir),
    }),
    target: centerAnimation,
    exit: (dir) => ({
      width: "100%",
      height: "100%",
      position: "absolute",
      overflow: "hidden",
      ...createTransformDirection(dir, true),
    }),
  };

  const direction = getDirection(location.pathname, lastLocation.pathname, isMobile);

  const animationProps = {
    variants: transitionVariants,
    custom: direction,
    initial: "initial",
    animate: "target",
    exit: "exit",
    transition,
  };

  return (
    <AuthenticationWrapper primary={theme.palette.primary.main} secondary={theme.palette.secondary.main}>
      <AuthBackground className="background behind" />
      <AnimatePresence mode="sync" custom={direction}>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <motion.div key="/" {...animationProps}>
              <DefaultPageWrapper>
                <OrganicLanding programAssets={programAssets} baseUrl={baseUrl} {...otherProps} />
              </DefaultPageWrapper>
            </motion.div>
          </Route>
          <Route path="/login">
            <motion.div key="/login" {...animationProps}>
              <CenterWrapper>
                <Login
                  hasEmailAuth={hasEmailAuth}
                  routePrefix={routePrefix}
                  programAssets={programAssets}
                  useCidp={useCidp}
                  cidpUser={cidpUser}
                  handleLoginRedirect={handleLoginRedirect}
                />
              </CenterWrapper>
            </motion.div>
          </Route>
          {useCidp && (
            <Route path="/account-update">
              <motion.div key="/account-update" {...animationProps}>
                <CenterWrapper>
                  <UpdateAccount
                    routePrefix={routePrefix}
                    programAssets={programAssets}
                    handleLoginRedirect={handleLoginRedirect}
                    {...otherProps}
                  />
                </CenterWrapper>
              </motion.div>
            </Route>
          )}
          {otherProps.playerId ? (
            <Route path="/create-account">
              <motion.div key="/create-account" {...animationProps}>
                <CenterWrapper>
                  <NewCreateAccount
                    routePrefix={routePrefix}
                    programAssets={programAssets}
                    useCidp={useCidp}
                    cidpUser={cidpUser}
                    handleLoginRedirect={handleLoginRedirect}
                    {...otherProps}
                  />
                </CenterWrapper>
              </motion.div>
            </Route>
          ) : (
            // eslint-disable-next-line no-return-assign
            <Route path="/create-account" render={() => (window.location = organicPath)} />
          )}
          <Route path="/value">
            <motion.div key="/value" {...animationProps}>
              <CenterWrapper>
                <ValueProposition programAssets={programAssets} {...otherProps} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/link-sent">
            <motion.div key="/link-sent" {...animationProps}>
              <CenterWrapper>
                <SentMagicLink programAssets={programAssets} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/password-reset">
            <motion.div key="/password-reset" {...animationProps}>
              <CenterWrapper>
                <PasswordReset programAssets={programAssets} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/password-reset-expired">
            <motion.div key="/password-reset-expired" {...animationProps}>
              <CenterWrapper>
                <PasswordResetExpired programAssets={programAssets} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/auth-token-expired">
            <motion.div key="/auth-token-expired" {...animationProps}>
              <CenterWrapper>
                <AuthTokenExpired programAssets={programAssets} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/reset_password/:token">
            <motion.div key="/reset_password/:token" {...animationProps}>
              <CenterWrapper>
                <PasswordResetForm routePrefix={routePrefix} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/email-confirm">
            <motion.div key="/email-confirm" {...animationProps}>
              <CenterWrapper>
                <EmailConfirm programAssets={programAssets} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="/property-disabled">
            <motion.div key="/property-disabled" {...animationProps}>
              <CenterWrapper>
                <PropertyDisabled programAssets={programAssets} baseUrl={baseUrl} />
              </CenterWrapper>
            </motion.div>
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </AnimatePresence>
    </AuthenticationWrapper>
  );
}

Authentication.propTypes = {
  routePrefix: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    propertyId: PropTypes.number,
  }).isRequired,
  propertyDisabled: PropTypes.bool.isRequired,
  hasEmailAuth: PropTypes.bool,
  useCidp: PropTypes.bool,
  cidpUser: PropTypes.bool,
};

Authentication.defaultProps = {
  hasEmailAuth: false,
  useCidp: false,
  cidpUser: false,
};
