import React, { useState, Fragment } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { string, object, ref } from "yup";
import { styled, useTheme } from "@mui/material/styles";
import MaterialFormControlLabel from "@mui/material/FormControlLabel";
import { CR_TOS_URL, CR_PRIVACY_URL } from "@constants/pathConstants";
import MaterialButton from "@components/atoms/material/MaterialButton";
import MaterialCheckBox from "@components/atoms/material/MaterialCheckBox";
import ErrorText from "@components/atoms/ErrorText";
import Typography from "@components/atoms/material/MaterialTypography";
import MaterialTextField from "@components/atoms/material/MaterialTextField";
import Spacing from "@components/layouts/Spacing";
import { createAccount, sendInvitationMagicLink } from "@services/usersService";
import { zIndexUnderlinedLink } from "@constants/zIndexConstants";

const UnderlinedLink = styled("a")`
  text-decoration: underline !important;
  z-index: ${zIndexUnderlinedLink};
`;

const MediumGreyLink = styled("a")`
  color: ${({ theme }) => theme.palette.mediumGrey.main || "grey"};
  text-decoration: underline !important;
`;

function EmailPage(props) {
  const { formik, email, setCurrentStep, history, playerId, useCidp, handleLoginRedirect } = props;
  const theme = useTheme();

  const TermsOfServiceLabel = (
    <Typography variant="caption">
      {useCidp ? "By clicking continue" : "By creating an account,"} you agree to the&nbsp;
      <UnderlinedLink rel="noopener noreferrer" target="_blank" href={CR_PRIVACY_URL} about="_blank">
        privacy policy
      </UnderlinedLink>
      &nbsp;and&nbsp;
      <UnderlinedLink rel="noopener noreferrer" target="_blank" href={CR_TOS_URL} about="_blank">
        terms of service
      </UnderlinedLink>
      .
    </Typography>
  );

  const onMagicLinkClick = () => {
    const params = { email: email || formik.values.email, player_id: playerId };
    try {
      formik.validateForm().then((errorMsg) => {
        if (errorMsg?.email === undefined) {
          formik.setSubmitting(true);
          sendInvitationMagicLink(params)
            .then(() => {
              history.push("/link-sent", { email: formik.values.email });
              formik.setSubmitting(false);
            })
            .catch((_e) => {
              formik.setErrors({ email: "something went wrong" });
              formik.setSubmitting(false);
            });
        }
      });
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  const onContinueButtonClick = (event) => {
    event.preventDefault();
    if (email && useCidp) {
      handleLoginRedirect(email, true);
    } else {
      onMagicLinkClick(event);
    }
  };

  const regularButtons = (
    <Fragment>
      <MaterialFormControlLabel
        variant="caption"
        color="mediumGrey"
        control={
          <MaterialCheckBox
            name="tosAccepted"
            checked={formik.values.tosAccepted}
            onChange={formik.handleChange}
            color="secondary"
          />
        }
        label={TermsOfServiceLabel}
      />
      <Spacing height="16" />

      <MaterialButton
        variant="contained"
        color="secondary"
        onClick={onMagicLinkClick}
        disabled={formik.isSubmitting || !formik.values.email || !formik.values.tosAccepted}
        fullWidth
      >
        Send Login Link
      </MaterialButton>
      <Spacing height="16" />
      <Typography variant="body2" align="center">
        Or:
      </Typography>
      <Spacing height="16" />
      <MaterialButton
        variant="text"
        color="secondary"
        data-testid="create-with-password-button"
        onClick={() => setCurrentStep(1)}
        disabled={formik.isSubmitting || !formik.values.email || !formik.values.tosAccepted}
        fullWidth
      >
        Continue with password
      </MaterialButton>
    </Fragment>
  );

  const cidpButtons = (
    <Fragment>
      <Typography variant="caption" align="right" style={{ width: "100%" }}>
        <MediumGreyLink style={{ alignSelf: "flex-end" }} theme={theme} to="/" href="/">
          Not You?
        </MediumGreyLink>
      </Typography>

      <Spacing height="24" />
      {TermsOfServiceLabel}

      <Spacing margin="16px 0" style={{ textAlign: "center" }}>
        <MaterialButton
          data-testid="create-account-continue"
          disabled={formik.isSubmitting || !formik.values.email}
          onClick={onContinueButtonClick}
          variant="contained"
          color="secondary"
          type="button"
          fullWidth
        >
          Continue
        </MaterialButton>
      </Spacing>
    </Fragment>
  );

  return (
    <React.Fragment>
      <Spacing marginBottom="8px">
        <Typography variant="subtitle2" align="left">
          Your Email
        </Typography>
        <ErrorText text={props.error?.email?.[0]} />
      </Spacing>

      <MaterialTextField
        name="email"
        variant="filled"
        disabled={!!email}
        onChange={formik.handleChange}
        value={formik.values.email}
        label={formik.errors.email ? formik.errors.email : "Enter Email"}
        placeholder="Email Address"
        error={!!formik.errors.email}
        type="email"
        required
        fullWidth
      />
      <Spacing height="16" />

      {!useCidp && regularButtons}

      {useCidp && cidpButtons}
    </React.Fragment>
  );
}

EmailPage.propTypes = {
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
      email: PropTypes.string.isRequired,
      tosAccepted: PropTypes.bool.isRequired,
    }).isRequired,
    errors: PropTypes.shape({
      email: PropTypes.string,
    }),
    isSubmitting: PropTypes.bool.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
  }).isRequired,
  email: PropTypes.string,
  setCurrentStep: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  playerId: PropTypes.number,
  error: PropTypes.shape({
    email: PropTypes.string,
  }),
  useCidp: PropTypes.bool,
  handleLoginRedirect: PropTypes.func.isRequired,
};

EmailPage.defaultProps = {
  email: null,
  playerId: null,
  error: {
    email: null,
  },
  useCidp: false,
};

function PasswordPage(props) {
  const { formik, trackLetsGoButtonClick } = props;
  return (
    <React.Fragment>
      <Spacing marginBottom="8px" marginTop="16px">
        <Typography variant="subtitle2" align="left">
          Create Password
        </Typography>
      </Spacing>
      <ErrorText text={formik.errors.password && formik.errors.password.length >= 35 && formik.errors.password} />
      <MaterialTextField
        name="password"
        onChange={formik.handleChange}
        variant="filled"
        error={!!formik.errors.password}
        label="Password"
        placeholder="Enter Password"
        type="password"
        required
        fullWidth
      />
      <Spacing height="16" />
      <MaterialTextField
        name="confirmPassword"
        onChange={formik.handleChange}
        variant="filled"
        error={!!formik.errors.confirmPassword}
        label={formik.errors.confirmPassword ? formik.errors.confirmPassword : "Confirm Password"}
        placeholder="Confirm Password"
        type="password"
        required
        fullWidth
      />
      <Spacing height="16" />
      <MaterialButton
        type="submit"
        variant="contained"
        color="secondary"
        onClick={trackLetsGoButtonClick}
        disabled={
          formik.isSubmitting ||
          !formik.values.email ||
          !formik.values.password ||
          !formik.values.confirmPassword ||
          !formik.values.tosAccepted
        }
        fullWidth
      >
        Let&#39;s go!
      </MaterialButton>
    </React.Fragment>
  );
}

PasswordPage.propTypes = {
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
      email: PropTypes.string.isRequired,
      tosAccepted: PropTypes.bool.isRequired,
      password: PropTypes.bool.isRequired,
      confirmPassword: PropTypes.string.isRequired,
    }).isRequired,
    errors: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
    }),
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  trackLetsGoButtonClick: PropTypes.func.isRequired,
};

const createAccountSchema = object({
  email: string().email().required(),
  password: string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,128}$/, {
      message: "must include 1 upper case letter, 1 lower case letter, 1 number, and be at least 10 characters",
      excludeEmptyString: true,
    })
    .required(),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

const pages = [EmailPage, PasswordPage];

function NewCreateAccountForm(props) {
  const { email, hasUser, playerId, useCidp, handleLoginRedirect } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();

  const initialFormValues = {
    email: email || "",
    password: "",
    confirmPassword: "",
    tosAccepted: false,
  };

  const onSubmit = async (params, formik) => {
    try {
      await createAccount(params);
      if (email === params.email) {
        window.location.reload();
      } else {
        history.push("/email-confirm", { email: params.email, emailSent: true });
      }
      formik.setSubmitting(false);
    } catch (e) {
      if (e?.errors?.email) {
        formik.setErrors({ email: e.errors.email });
        setCurrentStep(0);
      } else {
        formik.setErrors({ password: "Something went wrong!" });
      }
      formik.setSubmitting(false);
      console.log(e); // eslint-disable-line no-console
    }
  };

  const formik = useFormik({
    onSubmit,
    initialValues: initialFormValues,
    validationSchema: createAccountSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const CurrentPage = pages[currentStep];

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Spacing padding="0 24px" width="auto" display="flex" flexDirection="column" alignItems="center">
        <CurrentPage
          history={history}
          setCurrentStep={setCurrentStep}
          formik={formik}
          email={email}
          hasUser={hasUser}
          playerId={playerId}
          useCidp={useCidp}
          handleLoginRedirect={handleLoginRedirect}
        />
      </Spacing>
    </form>
  );
}

NewCreateAccountForm.propTypes = {
  email: PropTypes.string,
  hasUser: PropTypes.bool,
  playerId: PropTypes.number,
  useCidp: PropTypes.bool,
  handleLoginRedirect: PropTypes.func.isRequired,
};

NewCreateAccountForm.defaultProps = {
  email: undefined,
  hasUser: false,
  playerId: null,
  useCidp: false,
};

export default NewCreateAccountForm;
