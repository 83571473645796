import React from "react";
import { styled } from "@mui/material/styles";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import NewUserRegistration from "@components/features/NewUserRegistration";
import ValueProposition from "@components/features/ValueProposition";
import PropertySelect from "@components/features/PropertySelect";
import SentOrganicMagicLink from "@components/features/SentOrganicMagicLink";
import usePrevious from "@hooks/usePrevious";
import useWindowSize from "@hooks/useWindowSize";
import { media } from "@utils/style";

const CenterWrapper = styled("div")`
  display: flex;
  justify-content: center;
  height: 100%;
  ${media.mobile`
    height: unset;
  `}
`;

const transitionOffset = 100;

const getDirection = (newPath, lastPath, isMobile) => {
  const toRoot = newPath === "/";
  const fromRoot = lastPath === "/";

  if (isMobile) {
    return toRoot ? "left" : "right";
  }
  if (newPath === "/value" && fromRoot) return "down";
  if (!toRoot && !fromRoot && newPath !== "/value" && lastPath !== "/value") return "down";
  if (lastPath === "/value" && toRoot) return "up";
  if (toRoot && lastPath !== "/value") return "left";
  return "right";
};

const createTransformDirection = (direction, isExit = false) => {
  const offset = isExit ? transitionOffset : -transitionOffset;
  switch (direction) {
    case "up":
      return { y: `${offset}%` };
    case "down":
      return { y: `${-offset}%` };
    case "left":
      return { x: `${-offset}%` };
    case "right":
      return { x: `${offset}%` };
    default:
      return { x: `${offset}%` };
  }
};

const PropertySelectRouter = (props) => {
  const location = useLocation();
  const lastLocation = usePrevious(location) || { pathname: "/" };
  const { isMobile } = useWindowSize();

  const centerAnimation = { x: 0, y: 0, width: "100%", height: "100%" };
  const transition = { duration: 0.5 };

  const transitionVariants = {
    initial: (dir) => ({
      width: "100%",
      height: "100%",
      position: "absolute",
      overflow: "hidden",
      ...createTransformDirection(dir),
    }),
    target: centerAnimation,
    exit: (dir) => ({
      width: "100%",
      height: "100%",
      position: "absolute",
      overflow: "hidden",
      ...createTransformDirection(dir, true),
    }),
  };

  const direction = getDirection(location.pathname, lastLocation.pathname, isMobile);

  const animationProps = {
    variants: transitionVariants,
    custom: direction,
    initial: "initial",
    animate: "target",
    exit: "exit",
    transition,
  };

  return (
    <AnimatePresence mode="sync" custom={direction}>
      <Switch location={location} key={location.pathname}>
        <Route path="/" exact>
          <motion.div key="/" {...animationProps}>
            <NewUserRegistration {...props} />
          </motion.div>
        </Route>
        <Route path="/value">
          <motion.div key="/value" {...animationProps}>
            <ValueProposition {...props} noProperty />
          </motion.div>
        </Route>
        <Route path="/link-sent">
          <motion.div key="/link-sent" {...animationProps}>
            <CenterWrapper>
              <SentOrganicMagicLink />
            </CenterWrapper>
          </motion.div>
        </Route>
        <Route path="/property-select">
          <motion.div key="/property-select" {...animationProps}>
            <CenterWrapper>
              <PropertySelect {...props} />
            </CenterWrapper>
          </motion.div>
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

export default PropertySelectRouter;
